import React from 'react';

import HolidayModeCard from '@/components/cards/HolidayModeCard';
import UpdateBioCard from '@/components/cards/UpdateBioCard';

import ChartCard from '../components/cards/ChartCard';
import PendingPitchesCard from '../components/cards/PendingPitchesCard';
import PlaylistStatsCard from '../components/cards/PlaylistStatsCard';
import ToDo from '../components/cards/ToDo';
import TotalBalanceCard from '../components/cards/TotalBalanceCard';
import UserHeader from '../components/utility/navigation/UserHeader';

export default function HomePage() {
  document.title = 'Dashboard';

  return (
    <div className="page-content">
      <UserHeader isAddPlaylist={true} />
      <ToDo />
      <UpdateBioCard />
      <HolidayModeCard />
      <div className="d-flex gap16 mt16">
        <TotalBalanceCard navButton={true} />
        <PendingPitchesCard />
      </div>
      <div className="d-flex gap16 mt16">
        <PlaylistStatsCard />
        <ChartCard />
      </div>
    </div>
  );
}
